body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.buy-back-icon {
	height: 30px;
}

.buy-logout-icon {
	margin-left: 5px;
	height: 20px;
}

.custom-nav {
	height: 60px;
}

.c-pointer {
  cursor: pointer;
}

.bg-buy-main {
	background-color: #B80B4B
}

.bg-buy-primary {
	background-color: #09719E
}

.bg-buy-info {
	background-color: #479ABE
}

.border-buy-info {
	border-color: #479ABE
}

.border-buy-primary {
	border-color: #09719E
}

.border-buy-main {
	border-color: #B80B4B
}

.btn-buy-info, .btn-buy-info:hover, .btn-buy-info:not(:disabled):not(.disabled):active  {
	border-color: #479ABE;
	background-color: #479ABE;
}

.btn-buy-primary, .btn-buy-primary:hover, .btn-buy-primary:not(:disabled):not(.disabled):active {
	border-color: #09719E;
	background-color: #09719E;
}

.btn-buy-main, .btn-buy-main:hover, .btn-buy-main:not(:disabled):not(.disabled):active  {
	border-color: #B80B4B;
	background-color: #B80B4B;
}

